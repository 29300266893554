import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import "./BlogRoll.scss";

const BlogRoll = ({ homepage, data }) => {
  const { edges: posts = [] } = data.allMarkdownRemark;

  const postList = homepage ? posts.slice(0, 3) : posts;

  return (
    <div className={classnames("BlogRoll", { BlogRoll__homepage: homepage })}>
      <div className="BlogRoll__grid">
        {postList.map(({ node: post }) => {
          const {
            id,
            frontmatter,
            fields: { slug },
          } = post || {};
          const { featuredimage, title, date, description } = frontmatter || {};
          const localeDate = new Date(date).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          return (
            <div className="BlogRoll__card" key={id}>
              <article className="BlogRoll__cardContent">
                <header className="BlogRoll__header">
                  {featuredimage && (
                    <Link to={slug}>
                      <PreviewCompatibleImage
                        className="BlogRoll__cardImage"
                        imageInfo={{
                          image: featuredimage,
                          alt: `featured image thumbnail for post ${title}`,
                        }}
                      />
                    </Link>
                  )}
                </header>
                <div className="BlogRoll__cardBody">
                  <div className="BlogRoll__postInfos">
                    <Link className="BlogRoll__postTitle" to={slug}>
                      {title}
                    </Link>
                    <div className="BlogRoll__postDate">{localeDate}</div>
                    <p>{description}</p>
                  </div>
                  <Link className="BlogRoll__readPostButton button" to={slug}>
                    Lire la suite →
                  </Link>
                </div>
              </article>
            </div>
          );
        })}
      </div>
    </div>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ({ homepage }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll homepage={homepage} data={data} count={count} />
    )}
  />
);
